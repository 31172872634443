@use '@angular/material' as mat;

@mixin tablet-events-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Override styles for horizontal scroll
  .tablet-events-table {
    .zui-table-container {
      .table-container {
        display: block;

        .mat-table {
          width: 100%;
          max-width: 100%;
          display: table;
          border-collapse: collapse;

          .mat-column-compiledAppVersions {
            justify-content: flex-start;
            cursor: pointer;
            overflow: hidden; 
            text-overflow: ellipsis;
            max-width: 100%;
            padding: 2px;
            line-height: 1.5;
          }
          .mat-column-compiledAppVersions span {
            margin: 0;
            padding: 0;
          }
        
          .mat-column-batteryLevel,
          .mat-column-outboundIncompleteCount,
          .mat-column-outboundResentCount,
          .mat-column-batteryTemperature,
          .mat-column-batteryVoltage,
          .mat-column-availableStorage {
            justify-content: right;
          }
          span {
            margin-right: 1.125rem;
          }
        .mat-column-firmware .mat-sort-header-content,
        .mat-column-batteryLevel .mat-sort-header-content,
        .mat-column-outboundIncompleteCount .mat-sort-header-content,
        .mat-column-outboundResentCount .mat-sort-header-content,
        .mat-column-batteryTemperature .mat-sort-header-content,
        .mat-column-batteryVoltage .mat-sort-header-content,
        .mat-column-availableStorage .mat-sort-header-content {
        text-align: right;
          }
        .mat-sort-header-content {
          text-align: left;
          display: inline-block;
        }
         
        }
      }
    }
  }
}