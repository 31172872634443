@use '@angular/material' as mat;

@mixin inspection-device-events-table-theme($theme) {


  .inspection-device-events-table{
    .zui-table-container {
      .table-container{
        .mat-table {
          .mat-column-deviceId,
          .mat-column-firmwareVersion{
            text-align: right;
          }
        }
      }
    }

  }
}