@use '@angular/material' as mat;

@mixin device-health-chart-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .family-chart-container {
    .family-chart {
      height: 10rem;
    }

    .bar-chart {
        display: inline;
        .skeleton-bar {
            padding-left: 8%;
        }
    }

    .family-tooltip-container {
      > * {
        display: flex;
        flex: 1 100%;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flext-start;
        gap: 0.25rem;
      }

      .value {
        flex: 2;
        font-weight: bold;
        text-align: right;
      }
    } 
  }
}

@mixin icon {
  font-family: 'Material Icons';
  font-size: 1.25rem;
}