@use '@angular/material' as mat;

@mixin dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .dialog-container {
    justify-content: center;
    .info-icon {
      color: mat.get-color-from-palette($accent);
      cursor: pointer;
      float: right;
      
    }
    .dialog-content-container{
      box-shadow: 0 11px 15px -7px #29292933, 0 24px 38px 3px #29292924, 0 9px 46px 8px #2929291f;
      background: white;
      color: #292929de;
      border: 0px solid black;
      border-radius: 4px;
      justify-content: center;
      align-items: center
    }

      .close-button {
        @include mat.typography-level($zonar-default-typography, body-2);
        padding: 4px 20px !important;
        color: mat.get-color-from-palette($primary, 100);
        background-color: mat.get-color-from-palette($accent, default);
        border: none;
        border-radius: 8px;
        cursor: pointer;
      }
    
  }
  
}

