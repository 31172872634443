
@use '@angular/material' as mat;

@mixin tcu-events-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-column-address {
    min-width: 9.5rem;

    div {
      width: 100%;
    }

    .address-cell {
      cursor: pointer;
      color: mat.get-color-from-palette($accent, 'default');
      text-decoration: underline;
    }
  }

  .mat-table {
    width: 100%;
    max-width: 100%;
    display: table;
    border-collapse: collapse;
  
    .mat-column-gpssn,
    .mat-column-batteryVoltage,
    .mat-column-unsentGPS,
    .mat-column-unsentData {
      justify-content: right;
    }
    span {
      margin-right: 1.125rem;
    }
  .mat-column-gpssn .mat-sort-header-content,
  .mat-column-batteryVoltage .mat-sort-header-content,
  .mat-column-unsentData .mat-sort-header-content,
  .mat-column-unsentGPS .mat-sort-header-content {
  text-align: right;
    }
   
  }

}