@use "@angular/material" as mat;

@mixin phone-home-info-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

 

  .info-title {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 8px;
  }

  .sub-title {
    font-weight: 400;
    font-size: 16px;
    color: mat.get-color-from-palette($foreground, hint-text);
  }

  .body-content {
    font-size: 16px;
    color: mat.get-color-from-palette($foreground, text);
  }

  .close-button {
    @include mat.typography-level($zonar-default-typography, body-2);
    padding: 4px 20px !important;
    color: mat.get-color-from-palette($primary, 100);
    background-color: mat.get-color-from-palette($accent, default);
    border: none;
    border-radius: 3px;
    cursor: pointer;

}

.close-button:hover {
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
}
.link-content{
  color: mat.get-color-from-palette($accent, 400);
}
}
