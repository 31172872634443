@use '@angular/material'as mat;

@import '../../components/card/card.theme';
@import '../../components/mobile-drawer/mobile-drawer.component.theme';
@import '../../components/tablet-events-table/tablet-events-table.theme';
@import '../../components/inspection-device-events-table/inspection-device-events-table.theme';
@import '../../components/page-title/page-title.theme';
@import 'src/app/components/map-dialog/map-dialog.theme';
@import '../../components/tcu-events-table/tcu-events-table.theme';
@import '../../components/rfid-events-table/rfid-events-table.theme';
@import '../../components/device-readiness-counter/device-readiness-counter.component.theme';
@import '../../components/dialog/dialog.theme';
@import '../../components/device-health-issues-chart/device-health-issues-chart.component.theme';

@mixin overview-container-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  
  @include map-dialog-theme($theme);

  .overview-container {
    @include mobile-drawer-theme($theme);
    @include page-title-theme($theme);
    @include card-theme($theme);
    @include tablet-events-table-theme($theme);
    @include tcu-events-table-theme($theme);
    @include inspection-device-events-table-theme($theme);
    @include rfid-events-table-theme($theme);
    @include device-readiness-counter-theme($theme);
    @include dialog-theme($theme);
    @include device-health-chart-theme($theme);

    .data-viz-wrapper {
      margin-bottom: 1.75rem;
      margin-top: 1rem;
      justify-content: space-between;
      width: 100%;
      height: 21.875rem;

      .data-viz-child {
        .mat-card {
          margin: 0;
          height: 100%;
        }
      }
    }

    a {
      color: mat.get-color-from-palette($accent);
      text-decoration: underline;
    }

    .mat-tab-nav-panel {
      position: relative;
    }

    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, darker);
        text-decoration: none;
        opacity: 1;
      }

      .mat-tab-link:hover {
        background-color: mat.get-color-from-palette($accent, hover);
        color: mat.get-color-from-palette($primary, darker);
        border-bottom-width: 0.125rem;
        border-bottom-color: mat.get-color-from-palette($foreground, base);
        border-bottom-style: solid;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($foreground, base);
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }
  }

}