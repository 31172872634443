@use '@angular/material' as mat;

@import './components/device-readiness-counter-item/device-readiness-counter-item.component.theme';

@mixin device-readiness-counter-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .title-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: .5rem;
  }
  .card-title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 1.75rem;
  }
  .info-title{ 
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 8px;
  }
  .body-content {
    font-size: 16px;
    color: mat.get-color-from-palette($foreground, text);
  }

  .device-readiness-dialog {
    margin-left: auto;
  }

  .device-readiness-container {
    min-width: min-content;

    .device-readiness-dialog {
      flex-grow: 1;
      h4 {
        @include mat.typography-level($zonar-default-typography, subheading-1);
        font-weight: 400;
      }
    }

    @include device-readiness-counter-item-theme($theme);

    .device-readiness-counter-item {
      &.warning {
        margin-top: 1rem;
      }
      &.normal {
        margin-top: 1rem;
      }
    }

    .skeleton-grid-container {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;

      ngx-skeleton-loader {
        align-self: center;
      }
    }
  }
}
